@import url(./font.css);

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  min-width: 100%;
  min-height: 100vh;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
}
* {
  box-sizing: border-box;
}

select {
  border: 1px solid #ddd !important;
  font-size: 15 !important;
  min-width: 150px !important;
  padding: 12px !important;
}

.root {
  display: flex;
  flex-direction: column;
  flex: 1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
button {
  font-size: 18;
  font-family: "NotoSansKR-Bold";
}
.padding {
  margin: 0px 50px;
}
.row-center {
  display: flex;
  align-items: center;
}

.td-c {
  text-align: center !important;
}

.td-l {
  text-align: left !important;
}

.td-r {
  text-align: right !important;
  /* padding: 5px 5px 5px 5px !important; */
}

.k-cell-inner > .k-link {
  display: flex !important;
  justify-content: center !important;
}

.k-gird {
  width: 100% !important;
  position: relative;
}

.k-cell-inner > .k-link {
  justify-content: center;
}
/* .k-grid tbody{
  overflow: auto;
}

.k-grid table{
  overflow: auto;
  table-layout: fixed;
} */

.k-grid-contet table {
  table-layout: fixed;
}

/* .k-grid tbody{
  overflow: auto !important;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 50;
  display: table;
}


.k-grid table{
  overflow: auto !important;
  width:100%;
  display: table;
}  */

.grid-input {
  width: 200px;
  height: 30px;
  border: 1px solid #ddd;
  border-radius: 0.2rem;
}

.progress-bar {
  background-color: #fe6e0e;
  border-radius: 0.5rem;
  box-shadow: inset 0 0.5em 0.5em rgba(0, 0, 0, 0.05);
  height: 40px;
  overflow: hidden;
  position: absolute;
  transform: translateZ(0);
  width: 90%;
  z-index: 100;
  left: 5%;
  top: 45%;
}

.progress-bar__bar {
  background-color: #ececec;
  box-shadow: inset 0 0.5em 0.5em rgba(94, 49, 49, 0.05);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.progress-bar__bar.active {
  transition: all 3000ms ease-out;
  transform: translateX(100%);
  -webkit-transform: translateX(100%);
}

.k-grid .k-grid-header .k-grid-header-wrap table thead th {
  vertical-align: middle;
}

/* .k-cell-inner > .k-link{
  display: flex !important;
  justify-content: center !important;
} */

.k-cell-inner > .k-link {
  justify-content: center;
}

.react-pdf__Page__canvas {
  width: 100% !important;
  height: 40vh !important;
  overflow: scroll;
}

.react-datepicker__tab-loop {
  float: left;
}

.react-datepicker-popper {
  z-index: 2147483647 !important;
}

.react-datepicker__input-container {
  text-align: center;
}

.react-datepicker__input-container input {
  width: 200px;
  height: 30px;
  /* border: 1px solid #ededed; */
  border-radius: 0.3rem;
  border: none;
}

.k-dialog-wrapper .k-dialog {
  border-radius: 0.3rem;
}

.k-dialog-wrapper .k-dialog-titlebar {
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  background-color: #fff;
  color: #000;
}

.react-datepicker__input-container input:focus {
  outline: 1px solid #4583ee;
}

.k-checkbox:checked,
.k-checkbox.k-checked {
  border-color: #4583ee !important;
  color: white !important;
  background-color: #4583ee !important;
}

.k-checkbox:checked:focus,
.k-checkbox.k-checked.k-focus {
  box-shadow: 0 0 0 2px rgba(88, 155, 255, 0.3) !important;
}

.custom-animation-exit {
  transform: scale() 1;
  transition: transform 500ms ease-in-out;
}
.custom-animation-exit-active {
  transform: scale(0);
}

input[type="radio"] {
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

button {
  cursor: pointer;
}

.k-grid tbody td {
  padding: 3px;
}

.k-pager-info {
  display: block !important;
}

.k-input-values {
  display: flex;
}
